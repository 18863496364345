.tooltip {
  position: absolute;
  padding: 4px;
  max-width: 300px;
  font-size: 12px;
  border-radius: 5px;
  padding: 10px;
  background: white;
  line-height: 16px;
  z-index: 9;
  /* pointer-events: none; */
}

div.maplibregl-ctrl-bottom-right > details {
  display: none;
}

.SingleDatePicker_picker__portal {
  z-index: 999;
}
